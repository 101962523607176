import { createContext, useState } from "react";

const UserContext = createContext({});

export const UserProvider = ({children}) => {
    const [users, setUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    const searchModes = [
        { name: 'Search All', value: 'all' },
        { name: 'Search By Id', value: 'id' },
        { name: 'Search By Name', value: 'name' },
        { name: 'Search By Username', value: 'username' },
        { name: 'Search by Referral Codes', value: 'referral_codes' },
    ];
    const searchUserMethods = [
        { name: 'All Users', value: '' },
        { name: 'Verified', value: 'verified' },
        { name: 'Banned', value: 'banned' },
    ];
    const [selectedCurrency, setSelectedCurrency] = useState('IDR');

    return (
        <UserContext.Provider value={{ users, setUsers, totalRecords, setTotalRecords, expandedRows, setExpandedRows, searchModes, selectedCurrency, setSelectedCurrency, searchUserMethods }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;